<template>
  <en-dialog
    :model-value="modelValue"
    width="80%"
    :title="code === 'SAL' ? '销售单列表' : code === 'SALR' ? '销退单列表' : code === 'OTH' ? '其他出货单列表' : '移库出库单列表'"
    @close="$emit('update:model-value', false)"
  >
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>
    <form-query :model="form.data" :method="table.get">
      <en-form-item label="配件">
        <en-input v-model="form.data.goodsName"></en-input>
      </en-form-item>
      <en-form-item label="出库状态">
        <select-maintain
          v-model="form.data.stockOutProgressCode"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STKPRG']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="退货仓库" v-if="code === 'SALR' && code === 'OTH'">
        <select-maintain
          v-model="form.data.warehouseId"
          :ajax="{
            action: 'GET /enocloud/common/warehouse',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: 'id' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </form-query>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SALSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      :code="manifest.code"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      :summary-method="table.summaryMethod"
      show-summary
      pagination
      :config="table.config"
    >
      <template #RETURNED_COUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleQueryDto'] }">
        {{ row.returnedCount }}({{ formatPercent(row.returnedPercentage) }})
      </template>
      <template #STOCKED_COUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleQueryDto'] }">
        {{ row.stockedCount }}({{ formatPercent(row.stockedPercentage) }})
      </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
import { h } from 'vue'
import type { SummaryMethod, TableColumnCtx, TableInstance, ColumnStyle, CellStyle } from 'element-plus'
import { calculator } from '@enocloud/utils'
import { EnMessageBox } from '@enocloud/components'

export default factory({
  props: {
    modelValue: Boolean,
    code: {
      type: String as PropType<'SAL' | 'SALR' | 'OTH' | 'TRA'>,
      default: 'SAL'
    }
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        export: {
          async click() {
            switch (this.code) {
              case 'SAL':
                return this.ajax('GET /enocloud/sale/export', this.table.$ajaxParams, this.store.openDownload())
              case 'SALR':
                return this.ajax('GET /enocloud/sale/return/export', this.table.$ajaxParams, this.store.openDownload())
              case 'OTH':
                return this.ajax('GET /enocloud/sale/other/export', this.table.$ajaxParams, this.store.openDownload())
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      manifest: {
        computed: {
          code() {
            switch (this.code) {
              case 'SAL':
                return 'SALOFD'
              case 'SALR':
                return 'SALRFD'
              case 'OTH':
                return 'SALOTFD'
              case 'TRA':
                return 'STFOFD'
            }
          }
        }
      },

      form: {
        data: {
          typeCode: 'SAL',
          warehouseId: '',
          stockOutProgressCode: '',
          preparedByDepartmentId: '',
          goodsName: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/sale/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, statusCode: this.tabs.active, typeCode: this.code }
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['amountBeforeDiscount', 'amount', 'otherCharge', 'freight', 'receivableAmount','stockedAmount','returnCount','returnAmount','returnedCount','receivableBadDebt','returnedAmount','cost'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          STOCK_IN_SERIAL_NOS: { header: { filter: { type: 'text', field: 'stockInSerialNo' } } },
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          INVOICE_TYPE: { header: { filter: { type: 'text', field: 'invoiceTypeName' } } },
          LOGISTICS_COMPANY: { header: { filter: { type: 'text', field: 'logisticsCompany' } } },
          OPERATION: { visible: false },
          AUDIT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params) => (params.payload = { rsexp: 'id,name' })
                  },
                  filterable: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
              header: {
                filter: {
                  type: 'select',
                  field: 'clientId',
                  props: {
                    allowCreate: true,
                    ajax: {
                      action: 'GET /enocloud/common/customer',
                      params: (params, value) => (params.payload = { name: value,rsexp: 'id,name,cellphone'  })
                    },
                    props: { label: 'name', value: 'id' },
                    remote: true,
                    multiple: true
                  }
                }
              }
            },
            MODIFIED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SETTLEMENT_DATETIME: {
          header: {
            filter: {
              type: 'date',
              field: ['settlementStartDate', 'settlementEndDate'],
              prop:{type:'daterange'}
            }
          }
        },
        SETTLE_DATETIME: {
          header: {
            filter: {
              type: 'date',
              field: ['settledStartDate', 'settledEndDate'],
              prop:{type:'daterange'}
            }
          }
        },
          COMMENT: {
          header: {
            filter: {
              type: 'text',
              field: 'comment'
            }
          }
        },
            PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
            PREPARED_DATETIME: {
          header: {
            filter: {
              type: 'date',
              field: ['startDate', 'endDate'],
              props: { type: 'daterange' }
            }
          }
        },
        PAYMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'settlingAccountMethodCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['STLACMTD'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
        STOCK_OUT_DATETIME: {
          header: {
            filter: {
              type: 'date',
              field: ['outAuditStartDate', 'outAuditEndDate'],
              props: { type: 'daterange' }
            }
          }
        },
        OTHER_TYPE: {
          header: {
            filter: {
              type: 'text',
              field: 'otherType'
            }
          }
        },
        AUDIT_DATETIME: {
          header: {
            filter: {
              type: 'date',
              field: ['auditStartDate', 'auditEndDate'],
              props: { type: 'daterange' }
            }
          }
        },
            WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgressCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['STLMTPRG'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
         
          BUSINESS_DATE: { header: { filter: { type: 'date', field: ['businessStartDate', 'businessEndDate'], props: { type: 'daterange' } } } },
          COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
   
        },
        children: {}
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
